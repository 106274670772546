/* You can add global styles to this file, and also import other style files */
// @import "styles/_lib";
// @import "styles/_nav";
// @import "styles/_buttons";
// @import "styles/_cards";
// @import "styles/_base";
// @import "styles/_styles";

// TODO CCC: figure out better way to do these styles
tr.archived {
    background-color: rgb(255, 192, 203) !important;
}
tr.archived > td {
    background-color: rgb(255, 192, 203) !important;
}

.app-root-wrapper {
    background-color: #ecf0f5;
    min-height: 100vh;
}

@media screen and (max-width: 500px) {
    div.navbar-custom-menu {
        display: none;
    }

    span.mobile-app-name>b{
        font-size: 15px;
    }
}


@MainFMDColor: #B52228;
@MainFMDTextColor: white;

// Nav styling

.skin-blue .main-header .logo, .skin-blue .main-header .logo:hover, .skin-blue .main-header .navbar {
    background-color: @MainFMDColor;
}

.skin-blue .left-side, .skin-blue .main-sidebar, .skin-blue .wrapper {
    background-color: @MainFMDColor;
}

/* Mobile view hamburger menu button */
.skin-blue .main-header .navbar .sidebar-toggle:hover {
    background-color: @MainFMDColor;
}

/* Sidebar background highlight (no hover)  */
.skin-blue .sidebar-menu>li>.treeview-menu {
    background-color: @MainFMDColor;
}

/* Sidebar background highlight (hover) */
.skin-blue .sidebar-menu>li.active>app-nav-menu-item>a, .skin-blue .sidebar-menu>li:hover>app-nav-menu-item>a {
    background-color: darken(@MainFMDColor, 5%);
}

/* "NAVIGATION" sidebar nav header */
.skin-blue .sidebar-menu>li.header {
    color: @MainFMDTextColor;
    background: @MainFMDColor;
}

/* User Panel */
.skin-blue .main-header li.user-header {
    background-color: @MainFMDColor !important;
    .sign-out-button {
        background-color: @MainFMDTextColor;
        color: @MainFMDColor;
        border-color: @MainFMDColor
    }
    p {
        color: @MainFMDTextColor !important;
    }
}

/* Sidebar text */
.skin-blue .sidebar-menu>li.active>app-nav-menu-item>a, .skin-blue .sidebar-menu>li:hover>app-nav-menu-item>a,
.skin-blue .treeview-menu>li.active>app-nav-menu-item>a, .skin-blue .treeview-menu>li>app-nav-menu-item>a:hover,
.skin-blue .user-panel>.info, .skin-blue .user-panel>.info>a,
.skin-blue .sidebar a,
.navbar-toggle
{
    color: @MainFMDTextColor;
}


.view-all-inventory-modal {
    width: 80%;

    button.swal2-confirm {
        background-color: @MainFMDColor;
    }

    button:where(.swal2-styled).swal2-confirm:focus {
        box-shadow: none !important;
    }
}

.column-left-aligned {
    text-align: left;
}
